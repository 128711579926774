import React from "react"
import { Carousel } from "antd"
import styled from "styled-components"
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const CarouselContainer = styled(Carousel)`
  /* max-width: 1170px;
  min-width: 240px;
  margin: auto;
  padding: 30px 0; */
`
const ItemContainer = styled.div`
  /* position: relative;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    transition: all 0.25s linear;
  }
  &:hover::after {
    background: rgba(255, 255, 255, 0.2);
  } */
`
const ItemImage = styled.div`
  height: ${({ height }) => height ?? `300px`};
  width: ${({ width }) => width ?? `100%`};
  background-size: ${({ fill }) => fill ? `cover` : `contain`};;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
`
const Arrow = styled.div`
  color: black!important;
  font-size: 15px!important;
  line-height: 1.5715!important;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
`

const NextArrow = props => {
  const { onClick } = props
  return (
    <Arrow
      onClick={onClick}
      style={{ right: "-25px" }}
    >
      <RightOutlined />
    </Arrow >
  )
}

const PrevArrow = props => {
  const { onClick } = props
  return (
    <Arrow
      onClick={onClick}
      style={{ left: "-25px" }}
    >
      <LeftOutlined />
    </Arrow>
  )
}

const PostCarousel = ({ settings = {}, images, height, width, fill }) => {
  const slickSettings = {
    speed: 500,
    slidesToShow: images.length ?? 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ...settings
  }

  return (
    <CarouselContainer {...slickSettings}>
      {images
        .map((image, index) => (
          <ItemContainer key={`PostCarousel+${index}`}>
            <ItemImage
              height={height}
              width={width}
              fill={fill}
              style={{
                backgroundImage: `url(${image})`,
              }}
            ></ItemImage>
          </ItemContainer>
        ))}
    </CarouselContainer>
  )
}

export default PostCarousel
