import * as THREE from 'three'
import React, {useMemo} from 'react'
import { Canvas, useThree } from 'react-three-fiber'
import { OrbitControls } from 'drei' // 
import { useSpring } from 'react-spring'
import { Progress } from 'antd'

import { STLLoader } from '../utils/STLLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

import texture1 from '../../static/textures/Paint04_roughness_xtm.png'

function GLTFandDracoLoader(publicURL, lo, lp, le) {
    const loader = new GLTFLoader()
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://www.gstatic.com/draco/v1/decoders/' );
    loader.setDRACOLoader( dracoLoader );
    const lo2 = (scenes)=>{ return lo(scenes.scene) }
    return loader.load(publicURL, lo2, lp, le) 
}

const loaderList = {
    'stl': (publicURL, lo, lp, le) => {
        const loader = new STLLoader()
        return loader.load(publicURL, lo, lp, le) 
    },
    'glb':  GLTFandDracoLoader,
    'gltf':  GLTFandDracoLoader
}


function GroundPlane() {
    return (
        <mesh receiveShadow rotation={[90, 0, 0]} position={[0, 0, -5]}>
            <planeBufferGeometry attach="geometry" args={[500000, 500000]} />
            <meshStandardMaterial attach="material" color="blue" />
        </mesh>
    );
}

function Controls() {
    const { gl, camera } = useThree()
    
    useMemo(()=>{ 
        console.log("SETUP");
        camera.up.set(0, 1, 0) 
    },[camera])

    useSpring({
        from: { z: 300 },
        z: 1,
        onFrame: ({ z }) => {
            camera.position.set(z, z, z)
        }
    })

    return <OrbitControls autoRotate={true} target={[0, 0, 0]} args={[camera, gl.domElement]} />
}


class StlViewLoad extends React.Component {
    state = {
        model: null,
        progress: 0,
        errors: null,
        extension: null,
        gl: {
            toneMapping: THREE.ACESFilmicToneMapping,
			toneMappingExposure: 1.25,
			outputEncoding: THREE.sRGBEncoding,
            physicallyCorrectLights: true
        }
    }

    loadedObject(geometry) {
        let model = geometry
        let position = new THREE.Vector3(0,0,0)
        let scale = new THREE.Vector3(1,1,1)

        //IF the loaded module does not have an intrinsic texture make one
        if (this.state.extension === 'stl') {
            
            const textureLoader = new THREE.TextureLoader()
            const normalMap3 = textureLoader.load(texture1)

            let material = new THREE.MeshPhysicalMaterial({
                clearcoat: 1.0,
                clearcoatRoughness: 0.8,
                metalness: 0.9,
                roughness: 0.5,
                color: 0xFFbFa0,
                bumpMap: normalMap3
                //normalMap: normalMap3,
                //normalMapScale: [0.5,0.5]
            });

            geometry.computeBoundingBox();   
            let center = new THREE.Vector3();
            let size = new THREE.Vector3();
            geometry.boundingBox.getCenter(center);
            geometry.boundingBox.getSize(size);
            const norm = 1/Math.max(size.x,size.y,size.z)
            scale.multiplyScalar(norm)
            position = center.multiplyScalar(-norm)
            model = new THREE.Mesh(geometry, material)
        } 

        this.setState({ model, position, scale })
    }

    loadingProgress({ lengthComputable, loaded, total }) {
        if (lengthComputable) {
            this.setState({ progress: 100 * loaded / total })
        }
    }

    loadingError(err) {
        console.log(err)
        this.setState({ errors: JSON.stringify(err) })
    }

    componentDidMount() {
        const { name, frontmatter } = this.props;
        const assets = frontmatter.models
        const { publicURL, extension } = assets ? assets.find((a) => (a.name === name)) : { publicURL: "." };
        
        if (!loaderList[extension]) {
            this.setState({ errors: `Unable to load file ${publicURL} because there is no loader for ${extension}` })

        } else {
            this.setState({ extension, name, publicURL })
            const lo = this.loadedObject.bind(this)
            const lp = this.loadingProgress.bind(this)
            const le = this.loadingError.bind(this)
            this.__asyncLoader = loaderList[extension](publicURL, lo, lp, le)
        }
    }

    componentWillUnmount() {
        if (this.__asyncLoader) {
            console.log(this.__asyncLoader)
            this.__asyncLoader.cancel()
        }
    }

    render() {
        if (this.state.errors) {
            return (<div>{this.state.errors}</div>)

        } else if (this.state.model) {
            const style={ 
                width: this.props.width||'100%%', 
                height: this.props.height||'50em', 
                border: '1px solid black', 
                center: true 
            }

            return (
                <Canvas colorManagement gl={this.state.gl} style={style}>
                    <ambientLight intensity={2} />
                    <pointLight position={[0, 2, 0]} color={"#FFFFFF"} alpha={1} intensity={2} />
                    <pointLight position={[0, -2, 0]} color={"#FFFFFF"} alpha={1} intensity={2} />
                    <spotLight
                        position={[1, 1, 1]}
                        intensity={1}
                        penumbra={1}
                    />
                    <primitive object={this.state.model} position={this.state.position} scale={this.state.scale} />
                    
                    <Controls />
                </Canvas>
            )
        } else {
            return (<Progress type="circle" percent={this.state.progress} />)
        }
    }
}

export default StlViewLoad

/*

                    <spotLight
                        castShadow
                        position={[1, 1, 2]}
                        intensity={0.5}
                        penumbra={1}
                    />
  <Thing vertices={[[-1, 0, 0], [0, 1, 0], [1, 0, 0], [0, -1, 0], [-1, 0, 0]]} />



<group  dispose={null}>
                        <primitive object={this.state.model} dispose={null} />
                    </group>

position={[-1.5, -2.45, 0]} scale={[0.025,0.025,0.025]} rotation={[Math.PI/2, 0, 0]}
                <GroundPlane/>
            <OrbitControls />

*/