import React from "react"
import { Carousel } from "antd"
import styled from "styled-components"

const CarouselContainer = styled(Carousel)`
  max-width: 1170px;
  min-width: 240px;
  margin: auto;
  padding: 30px 0;
`
const ItemContainer = styled.div`
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    transition: all 0.25s linear;
  }
  &:hover::after {
    background: rgba(255, 255, 255, 0.2);
  }
`
const ItemImage = styled.div`
  height: 300px;
`
const ItemTitle = styled.h2`
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 15px;
  margin: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  height: 60%;
`

const TopCarousel = () => {
  const settings = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  }
  return (
    <CarouselContainer {...settings}>
      {Array(4)
        .fill("http://placekitten.com/355/30")
        .map((i, index) => (
          <ItemContainer key={`TopCarousel+${index}`}>
            <ItemImage
              style={{
                backgroundImage: `url(${i}${index})`,
              }}
            ></ItemImage>
            <ItemTitle>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </ItemTitle>
          </ItemContainer>
        ))}
    </CarouselContainer>
  )
}

export default TopCarousel
