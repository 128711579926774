import React from "react"
import { Layout, Row, Col, Divider, List } from "antd"
import { Link } from "gatsby"
import styled from "styled-components"

const { Footer: FooterAntd } = Layout
const categoryList = ["Programming", "Manufacturing", "Retro", "Drone"]

const FooterContainer = styled(FooterAntd)`
  background-color: #fafafa;
  margin-top: 30px;
  width: 100%;
`
const FooterContent = styled.div`
  margin: auto;
  max-width: 1170px;
  min-width: 240px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Row gutter={[48, 8]} justify="space-around">
          <Col md={12}>
            Written by David Middleton who lives and works in Brisbane,
            Australia building useful things.
            <br />
            <a
              href="https://twitter.com/peon374"
              rel="noreferrer"
              target="_blank"
            >
              Twitter
            </a>
            <br />
            <a
              href="https://www.linkedin.com/in/david-middleton-a065b11/"
              rel="noreferrer"
              target="_blank"
            >
              LinkedIn
            </a>
          </Col>
          <Col md={6}>
            <h3>Categories</h3>
            <Divider />
            <List
              dataSource={categoryList}
              renderItem={item => (
                <List.Item>
                  <Link
                    href="#"
                    to={`/category/${item.toLowerCase()}`}
                    target="_blank"
                  >
                    {item}
                  </Link>
                </List.Item>
              )}
            />
          </Col>
          <Col md={6}>
            <h3>Sitemap</h3>
            <Divider />
            <List>
              <List.Item key="1">
                <Link to={`/`}>Home</Link>
              </List.Item>
              <List.Item key="2">
                <Link to={`/posts`}>Posts</Link>
              </List.Item>
              <List.Item key="3">
                <Link to={`/projects`}>Projects</Link>
              </List.Item>
              <List.Item key="4">
                <Link to={`/about`}>About</Link>
              </List.Item>
            </List>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[48, 8]} justify="space-between">
          <Col>
            {`Copyright ©  David Middleton ${new Date().getFullYear()}.`}
          </Col>
        </Row>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer
