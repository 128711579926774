import React, { useContext } from "react"
import styled from "styled-components"
import { Layout, Radio } from "antd"
import { FaList, FaThList, FaThLarge } from "react-icons/fa"
import { ContentContext, postTypes } from "../utils/ContentContext"

const { Content } = Layout

const Container = styled(Content)`
  width: 100%;
  max-width: 1170px;
  min-width: 240px;
  margin: 20px auto;
`
const ContentTypes = styled(Radio.Group)`
  margin-left: auto;
  padding-top: 2px;
`
const Header = styled.div`
  display: flex;
`
const PostContent = styled.div`
  margin: 20px 0;
`
const Title = styled.h2`
  text-transform: uppercase;
`

const ContentContainer = ({ location, children }) => {
  const { state, dispatch } = useContext(ContentContext)
  const { postType } = state
  const locationName = location.pathname.split("/").pop()

  const setPostType = e => {
    dispatch({
      type: "setPostsType",
      data: {
        postType: e.target.value,
      },
    })
  }

  return (
    <Container>
      {location.pathname !== "/post" && location.pathname !== "/about" && (
        <>
          <Header>
            <Title>{location.pathname === "/" ? "LATEST" : locationName}</Title>
            <ContentTypes buttonStyle="solid" value={postType}>
              <Radio.Button onClick={setPostType} value={postTypes[0]}>
                <FaList size="1em" />
              </Radio.Button>
              <Radio.Button onClick={setPostType} value={postTypes[1]}>
                <FaThList size="1em" />
              </Radio.Button>
              <Radio.Button onClick={setPostType} value={postTypes[2]}>
                <FaThLarge size="1em" />
              </Radio.Button>
            </ContentTypes>
          </Header>
          <hr />
        </>
      )}
      <PostContent>{children}</PostContent>
    </Container>
  )
}

export default ContentContainer
