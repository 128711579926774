import React from "react"
import { Layout as LayoutAntd } from "antd"

import "antd/dist/antd.css"
import TopCarousel from "./topCarousel"
import ContentContainer from "./contentContainer"
import { ContentStateProvider } from "../utils/ContentContext"
import Header from "./header"
import Footer from "./footer"

// const { Sider } = LayoutAntd

const Layout = ({ location, title, children, frontmatter }) => {
  return (
    <ContentStateProvider frontmatter={frontmatter}>
      <LayoutAntd className="layout">
        <Header title={title} location={location} />
        <LayoutAntd>{location.pathname === "/" && <TopCarousel />}</LayoutAntd>
        <LayoutAntd>
          <ContentContainer location={location}>{children}</ContentContainer>
        </LayoutAntd>
        <Footer />
      </LayoutAntd>
    </ContentStateProvider>
  )
}

export default Layout
