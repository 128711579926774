import React, { createContext, useReducer } from "react"
import update from "immutability-helper"
import { MDXProvider } from "@mdx-js/react"

import StlView from '../components/stlview'
import PostCarousel from '../components/postCarousel'
import { keyframes } from "styled-components"

export const postTypes = ["list", "large-list", "grid"]
const postType = { postType: postTypes[0] }

export const ContentContext = createContext(postType)
const { Provider } = ContentContext

const setPostsType = (state, action) => {
  let newState = update(state, {
    postType: { $set: action.data.postType },
  })
  return newState
}

const shortcodes = {
  StlView,
  PostCarousel,
}

export const ContentStateProvider = (props) => {
  const { children, post, frontmatter } = props
  console.log(props)
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "setPostsType":
        return setPostsType(state, action)
      default:
        throw new Error()
    }
  }, postType)

  const shortcodes = {
    StlView: (nprops)=>{ return (<StlView frontmatter={frontmatter} {...nprops} />) },
    PostCarousel,
  }

  return (
    <MDXProvider components={shortcodes}>
      <Provider value={{ state, dispatch }}>{children}</Provider>
    </MDXProvider>
  )
}
