import React, { useState } from "react"
import { Layout, Menu, Drawer } from "antd"
import { Link } from "gatsby"
import styled from "styled-components"
import { LinkedinFilled, TwitterOutlined } from "@ant-design/icons"
import { FaBars } from "react-icons/fa"

const { Header: HeaderAntd } = Layout

const HeaderContainer = styled(HeaderAntd)`
  position: relative;
  height: 100%;
  width: 100%;
`
const HeaderContent = styled.div`
  position: relative;
  display: flex;
  max-width: 1170px;
  min-width: 240px;
  margin: auto;
`
const Title = styled(Link)`
  color: white;
  display: flex;
  font-size: 1.5em;
  margin-right: 25px;
`
const LeftMenuContainer = styled.div`
  display: flex;
  float: left;
  overflow: hidden;
  @media (max-width: 810px) {
    display: none;
  }
`
const RightMenuContainer = styled.div`
  display: flex;
  float: right;
  margin-left: auto;
  overflow: hidden;
  @media (max-width: 810px) {
    display: none;
  }
`
const BarsMenu = styled.div`
  color: white;

  float: right;
  margin-left: auto;
  overflow: hidden;
  display: none;
  @media (max-width: 810px) {
    display: flex;
  }
`

const LeftMenu = ({ mode }) => {
  return (
    <Menu mode={mode} theme={mode === "horizontal" ? "dark" : "light"}>
      <Menu.Item key="1">
        <Link to={`/`}>HOME</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={`/posts`}>POSTS</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={`/projects`}>PROJECTS</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={`/about`}>ABOUT</Link>
      </Menu.Item>
    </Menu>
  )
}
const RightMenu = ({ mode }) => {
  return (
    <Menu mode={mode} theme={mode === "horizontal" ? "dark" : "light"}>
      <Menu.Item key="Twitter">
        <a href="https://twitter.com/peon374" rel="noreferrer" target="_blank">
          <TwitterOutlined />
          Twitter
        </a>
      </Menu.Item>
      <Menu.Item key="LinkedIn">
        <a
          href="https://www.linkedin.com/in/david-middleton-a065b11/"
          rel="noreferrer"
          target="_blank"
        >
          <LinkedinFilled />
          LinkedIn
        </a>
      </Menu.Item>
    </Menu>
  )
}

const Header = ({ title }) => {
  const [visible, setVisible] = useState(false)

  return (
    <HeaderContainer>
      <HeaderContent>
        <Title to={"/"}>{title}</Title>
        <LeftMenuContainer>
          <LeftMenu mode="horizontal" />
        </LeftMenuContainer>
        <RightMenuContainer>
          <RightMenu mode="horizontal" />
        </RightMenuContainer>
        <BarsMenu>
          <FaBars size="2em" onClick={() => setVisible(true)} />
        </BarsMenu>
        <Drawer
          title={title}
          placement="right"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <LeftMenu />
          <RightMenu />
        </Drawer>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Header
